import React from "react"
import SEO from "../components/seo"
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  min-height: 90vh;
  text-align: center;
  align-items: center;
  flex-direction: column;
`

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Sorry, the page you were looking for doesn't exist.</p>
  </Container>
)

export default NotFoundPage
